var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"overflow-auto",attrs:{"flat":"","height":_vm.height}},[_c('v-card-title',{staticClass:"ml-8",staticStyle:{"font-size":"large"}},[_vm._v("Enable / Disable the types of Scans required at Organization Level")]),_c('v-card-subtitle',{staticClass:"ml-8"},[_vm._v("Further to this, the settings under each member can be adjusted to grant or restrict access to a particular type of scan.")]),_c('v-row',{staticClass:"mr-4 ml-4 mt-n8"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mt-5 ml-3 fixed-height-card"},[_c('v-card-title',{staticStyle:{"font-size":"medium"}},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("mdi-qrcode")]),_vm._v(" QR "),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""}},on),[_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-comment-question-outline ")])],1)]}}])},[_c('div',{staticClass:"textWrap white--text",staticStyle:{"max-width":"250px"}},[_vm._v(" A unique QR code is generated for each location created within your organization. These QR codes are geo-tagged, allowing users to scan them to register their attendance. ")])]),_c('v-spacer'),_c('v-switch',{staticClass:"mr-2",attrs:{"dense":"","label":(" " + (_vm.is_qr_enabled.toString() === 'true' ? 'Yes' : 'No')),"inset":"","disabled":""},on:{"click":function($event){return _vm.Qr_swipe()}},model:{value:(_vm.is_qr_enabled),callback:function ($$v) {_vm.is_qr_enabled=$$v},expression:"is_qr_enabled"}})],1),_c('v-card-text',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("By default, enabled at the organization level (Cannot be disabled).")]),_c('br'),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Is enabled for all users (Cannot be disabled).")])])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mt-5 ml-3 fixed-height-card"},[_c('v-card-title',{staticStyle:{"font-size":"medium"}},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("mdi-line-scan ")]),_vm._v(" Simple "),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""}},on),[_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-comment-question-outline ")])],1)]}}])},[_c('div',{staticClass:"textWrap white--text",staticStyle:{"max-width":"250px"}},[_vm._v(" This basic scan feature marks attendance for individuals without requiring QR or Face Scan, while also capturing the location of the scan. ")])]),_c('v-spacer'),_c('v-switch',{staticClass:"mr-2",attrs:{"dense":"","label":(" " + (_vm.is_simple_scan.toString() === 'true' ? 'Yes' : 'No')),"inset":""},on:{"click":function($event){return _vm.simplescan_swipe()}},model:{value:(_vm.is_simple_scan),callback:function ($$v) {_vm.is_simple_scan=$$v},expression:"is_simple_scan"}})],1),_c('v-card-text',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Can be enabled or disabled at the organization level.")]),_c('br'),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Granting access to individuals necessitates enabling it at the organization level.")])])],1)],1)],1),_c('v-row',{staticClass:"mr-4 ml-4 mt-n4"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mt-5 ml-3"},[_c('v-card-title',{staticStyle:{"font-size":"medium"}},[_c('v-icon',{attrs:{"color":"green","left":""}},[_vm._v(" mdi-face-recognition ")]),_vm._v(" Face "),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""}},on),[_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-comment-question-outline ")])],1)]}}])},[_c('div',{staticClass:"textWrap white--text",staticStyle:{"max-width":"250px"}},[_vm._v(" Identifies a person by facial features and do not require any custom hardware to record attendance. ")])]),_c('v-spacer'),_c('v-switch',{staticClass:"mr-2",attrs:{"dense":"","label":(" " + (_vm.is_face_enabled.toString() === 'true' ? 'Yes' : 'No')),"inset":""},on:{"click":function($event){return _vm.face_swipe()}},model:{value:(_vm.is_face_enabled),callback:function ($$v) {_vm.is_face_enabled=$$v},expression:"is_face_enabled"}})],1),_c('v-card-text',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Can be enabled or disabled at the organization level.")]),_c('br'),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Granting access to individuals necessitates enabling it at the organization level.")])])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mt-5 ml-3"},[_c('v-card-title',{staticStyle:{"font-size":"medium"}},[_c('v-icon',{attrs:{"color":"red","left":""}},[_vm._v(" mdi-odnoklassniki ")]),_vm._v(" Proxy "),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""}},on),[_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-comment-question-outline ")])],1)]}}])},[_c('div',{staticClass:"textWrap white--text",staticStyle:{"max-width":"250px"}},[_vm._v(" Allows admins to register attendance for any user who lacks a smartphone, using any available scan method. ")])]),_c('v-spacer'),_c('v-switch',{staticClass:"mr-2",attrs:{"dense":"","label":(" " + (_vm.is_proxy_enabled.toString() === 'true' ? 'Yes' : 'No')),"inset":""},on:{"click":function($event){return _vm.proxy_swipe()}},model:{value:(_vm.is_proxy_enabled),callback:function ($$v) {_vm.is_proxy_enabled=$$v},expression:"is_proxy_enabled"}})],1),_c('v-card-text',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Can be enabled or disabled at the organization level.")]),_c('br'),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Granting access to individuals necessitates enabling it at the organization level.")])])],1)],1)],1),_c('v-row',{staticClass:"mr-4 ml-4 mt-n4"},[_c('v-col',{attrs:{"cols":"6 "}},[_c('v-card',{staticClass:"mt-3 ml-3 fixed-height-card"},[_c('v-card-title',{staticStyle:{"font-size":"medium"}},[_c('v-icon',{attrs:{"color":"red","left":""}},[_vm._v(" mdi-arrow-expand-all ")]),_vm._v(" Scan Anywhere "),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""}},on),[_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-comment-question-outline ")])],1)]}}])},[_c('div',{staticClass:"textWrap white--text",staticStyle:{"max-width":"250px"}},[_vm._v(" Enable access to field staff for scanning from anywhere outside the organization's geo-fence. ")])]),_c('v-spacer'),_c('v-switch',{staticClass:"mr-2",attrs:{"disabled":"","dense":"","label":(" " + (_vm.is_scananywhere.toString() === 'true' ? 'Yes' : 'No')),"inset":""},model:{value:(_vm.is_scananywhere),callback:function ($$v) {_vm.is_scananywhere=$$v},expression:"is_scananywhere"}})],1),_c('v-card-text',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("By default, enabled at the organization level and disabled at individual level.")]),_c('br'),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Access can be enabled or disabled at individual level.")])])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mt-3 ml-3 fixed-height-card"},[_c('v-card-title',{staticStyle:{"font-size":"medium"}},[_c('v-icon',{attrs:{"color":"black","left":""}},[_vm._v(" mdi-bluetooth-audio ")]),_vm._v(" Beacon "),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""}},on),[_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-comment-question-outline ")])],1)]}}])},[_c('div',{staticClass:"textWrap white--text",staticStyle:{"max-width":"250px"}},[_vm._v(" You can procure a beacon from us and have it configured for your organization. The beacon utilizes bluetooth technology and serves as a viable alternative in areas where GPS signals are weak. ")])]),_c('v-spacer'),_c('v-switch',{staticClass:"mr-2",attrs:{"dense":"","label":(" " + (_vm.is_beacon_enabled.toString() === 'true' ? 'Yes' : 'No')),"inset":""},on:{"click":function($event){return _vm.beacon_swipe()}},model:{value:(_vm.is_beacon_enabled),callback:function ($$v) {_vm.is_beacon_enabled=$$v},expression:"is_beacon_enabled"}})],1),_c('v-card-text',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Enable if your organization has procured a beacon.")]),_c('br'),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Access can be enabled or disabled at individual level.")])])],1)],1)],1),_c('v-row',{staticClass:"mr-4 ml-4 mt-n4"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mt-3 ml-3"},[_c('v-card-title',{staticStyle:{"font-size":"medium"}},[_c('v-icon',{attrs:{"color":"red","left":""}},[_vm._v(" mdi-cellphone ")]),_vm._v(" Phone Number "),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""}},on),[_c('v-icon',{staticClass:"primary--text"},[_vm._v("mdi-comment-question-outline ")])],1)]}}])},[_c('div',{staticClass:"textWrap white--text",staticStyle:{"max-width":"250px"}},[_vm._v(" During member onboarding, it becomes mandatory to enter their mobile number. ")])]),_c('v-spacer'),_c('v-switch',{staticClass:"mr-2",attrs:{"dense":"","label":(" " + (_vm.is_member_phone.toString() === 'true' ? 'Yes' : 'No')),"inset":""},on:{"click":function($event){return _vm.phone_numbercall()}},model:{value:(_vm.is_member_phone),callback:function ($$v) {_vm.is_member_phone=$$v},expression:"is_member_phone"}})],1),_c('v-card-text',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Enable if you wish to make phone number mandatory for all users.")])])],1)],1)],1)],1),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }