<template>
  <div>
    <v-card flat :height="height" class="overflow-auto">
      <v-card-title class="ml-8" style="font-size: large"
        >Enable / Disable the types of Scans required at Organization
        Level</v-card-title
      >
      <v-card-subtitle class="ml-8"
        >Further to this, the settings under each member can be adjusted to
        grant or restrict access to a particular type of scan.</v-card-subtitle
      >
      <v-row class="mr-4 ml-4 mt-n8">
        <v-col cols="6">
          <v-card class="mt-5 ml-3 fixed-height-card">
            <v-card-title style="font-size: medium">
              <v-icon color="primary" left>mdi-qrcode</v-icon>
              QR
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="ml-2" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <div class="textWrap white--text" style="max-width: 250px">
                  A unique QR code is generated for each location created within
                  your organization. These QR codes are geo-tagged, allowing
                  users to scan them to register their attendance.
                </div>
              </v-tooltip>
              <v-spacer />
              <v-switch
                v-model="is_qr_enabled"
                @click="Qr_swipe()"
                dense
                :label="` ${
                  is_qr_enabled.toString() === 'true' ? 'Yes' : 'No'
                }`"
                inset
                class="mr-2"
                disabled
              >
              </v-switch>
            </v-card-title>
            <v-card-text>
              <span class="font-weight-medium"
                >By default, enabled at the organization level (Cannot be
                disabled).</span
              >
              <br />
              <span class="font-weight-medium"
                >Is enabled for all users (Cannot be disabled).</span
              >
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="mt-5 ml-3 fixed-height-card">
            <v-card-title style="font-size: medium">
              <v-icon color="primary" left>mdi-line-scan </v-icon>
              Simple
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="ml-2" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <div class="textWrap white--text" style="max-width: 250px">
                  This basic scan feature marks attendance for individuals
                  without requiring QR or Face Scan, while also capturing the
                  location of the scan.
                </div>
              </v-tooltip>
              <v-spacer />
              <v-switch
                v-model="is_simple_scan"
                @click="simplescan_swipe()"
                dense
                :label="` ${
                  is_simple_scan.toString() === 'true' ? 'Yes' : 'No'
                }`"
                inset
                class="mr-2"
              >
              </v-switch>
            </v-card-title>
            <v-card-text>
              <span class="font-weight-medium"
                >Can be enabled or disabled at the organization level.</span
              >
              <br />
              <span class="font-weight-medium"
                >Granting access to individuals necessitates enabling it at the
                organization level.</span
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mr-4 ml-4 mt-n4">
        <v-col cols="6">
          <v-card class="mt-5 ml-3">
            <v-card-title style="font-size: medium">
              <v-icon color="green" left> mdi-face-recognition </v-icon>
              Face
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="ml-2" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <div class="textWrap white--text" style="max-width: 250px">
                  Identifies a person by facial features and do not require any
                  custom hardware to record attendance.
                </div>
              </v-tooltip>
              <v-spacer />
              <v-switch
                v-model="is_face_enabled"
                dense
                :label="` ${
                  is_face_enabled.toString() === 'true' ? 'Yes' : 'No'
                }`"
                inset
                @click="face_swipe()"
                class="mr-2"
              >
              </v-switch>
            </v-card-title>
            <v-card-text>
              <span class="font-weight-medium"
                >Can be enabled or disabled at the organization level.</span
              >
              <br />
              <span class="font-weight-medium"
                >Granting access to individuals necessitates enabling it at the
                organization level.</span
              >
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="mt-5 ml-3">
            <v-card-title style="font-size: medium">
              <v-icon color="red" left> mdi-odnoklassniki </v-icon>
              Proxy
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="ml-2" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <div class="textWrap white--text" style="max-width: 250px">
                  Allows admins to register attendance for any user who lacks a
                  smartphone, using any available scan method.
                </div>
              </v-tooltip>
              <v-spacer />
              <v-switch
                v-model="is_proxy_enabled"
                dense
                :label="` ${
                  is_proxy_enabled.toString() === 'true' ? 'Yes' : 'No'
                }`"
                inset
                @click="proxy_swipe()"
                class="mr-2"
              >
              </v-switch>
            </v-card-title>
            <v-card-text>
              <span class="font-weight-medium"
                >Can be enabled or disabled at the organization level.</span
              >
              <br />
              <span class="font-weight-medium"
                >Granting access to individuals necessitates enabling it at the
                organization level.</span
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mr-4 ml-4 mt-n4">
        <v-col cols="6 ">
          <!-- <v-card class="mt-3 ml-3">
            <v-card-title style="font-size: medium">
              <v-icon color="green" left> mdi-whatsapp </v-icon>
              Enable Whatsapp Scans for Organization
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-center">
                <v-switch
                  v-model="is_whatsapp"
                  dense
                  :label="` ${
                    is_whatsapp.toString() === 'true' ? 'Yes' : 'No'
                  }`"
                  inset
                  @click="whatsapp_swipe()"
                  class="mt-n2"
                >
                </v-switch>
              </div>
            </v-card-text>
          </v-card> -->
          <v-card class="mt-3 ml-3 fixed-height-card">
            <v-card-title style="font-size: medium">
              <v-icon color="red" left> mdi-arrow-expand-all </v-icon>
              Scan Anywhere
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="ml-2" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <div class="textWrap white--text" style="max-width: 250px">
                  Enable access to field staff for scanning from anywhere
                  outside the organization's geo-fence.
                </div>
              </v-tooltip>
              <v-spacer />
              <v-switch
                disabled
                v-model="is_scananywhere"
                dense
                :label="` ${
                  is_scananywhere.toString() === 'true' ? 'Yes' : 'No'
                }`"
                inset
                class="mr-2"
              >
              </v-switch>
            </v-card-title>
            <v-card-text>
              <span class="font-weight-medium"
                >By default, enabled at the organization level and disabled at
                individual level.</span
              >
              <br />
              <span class="font-weight-medium"
                >Access can be enabled or disabled at individual level.</span
              >
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="mt-3 ml-3 fixed-height-card">
            <v-card-title style="font-size: medium">
              <v-icon color="black" left> mdi-bluetooth-audio </v-icon>
              Beacon
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="ml-2" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <div class="textWrap white--text" style="max-width: 250px">
                  You can procure a beacon from us and have it configured for
                  your organization. The beacon utilizes bluetooth technology
                  and serves as a viable alternative in areas where GPS signals
                  are weak.
                </div>
              </v-tooltip>
              <v-spacer />
              <v-switch
                v-model="is_beacon_enabled"
                dense
                :label="` ${
                  is_beacon_enabled.toString() === 'true' ? 'Yes' : 'No'
                }`"
                inset
                @click="beacon_swipe()"
                class="mr-2"
              >
              </v-switch>
            </v-card-title>
            <v-card-text>
              <span class="font-weight-medium"
                >Enable if your organization has procured a beacon.</span
              >
              <br />
              <span class="font-weight-medium"
                >Access can be enabled or disabled at individual level.</span
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mr-4 ml-4 mt-n4">
        <!-- <v-col cols="6">
          <v-card class="mt-3 ml-3">
            <v-card-title style="font-size: medium">
              <v-icon color="primary" left>mdi-eye </v-icon>
              Proxy Face Eye Blink  <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="ml-2" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <div class="textWrap white--text" style="max-width: 250px">
                Allows admins to register attendance for any user who lacks a smartphone, using any available scan method.
                </div>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-center">
                <v-switch
                  v-model="is_prxyface_enabled"
                  dense
                  :label="` ${
                    is_prxyface_enabled.toString() === 'true' ? 'Yes' : 'No'
                  }`"
                  inset
                  @click="proxy_eyeswipe()"
                  class="mt-n2"
                >
                </v-switch>
              </div>
              <span class="font-weight-medium"
                >Enable if you wish eye blink for a proxy face scan .</span
              >
            </v-card-text>
          </v-card>
        </v-col> -->

        <v-col cols="6">
          <v-card class="mt-3 ml-3">
            <v-card-title style="font-size: medium">
              <v-icon color="red" left> mdi-cellphone </v-icon>
              Phone Number
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="ml-2" icon>
                    <v-icon class="primary--text"
                      >mdi-comment-question-outline
                    </v-icon>
                  </v-btn>
                </template>
                <div class="textWrap white--text" style="max-width: 250px">
                  During member onboarding, it becomes mandatory to enter their
                  mobile number.
                </div>
              </v-tooltip>
              <v-spacer />
              <v-switch
                v-model="is_member_phone"
                dense
                :label="` ${
                  is_member_phone.toString() === 'true' ? 'Yes' : 'No'
                }`"
                inset
                @click="phone_numbercall()"
                class="mr-2"
              >
              </v-switch>
            </v-card-title>
            <v-card-text>
              <span class="font-weight-medium"
                >Enable if you wish to make phone number mandatory for all
                users.</span
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row class="mr-4 ml-4 mt-n4">
      </v-row> -->
      <!-- <v-row class="mr-4 ml-4 mt-n5">
        <v-col cols="6 ">
          <v-card class="mt-3 ml-3">
            <v-card-title style="font-size: medium">
              <v-icon color="red" left> mdi-arrow-expand-all </v-icon>
              Enable Scan AnyWhere for Organization
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-center">
                <v-switch
                  disabled
                  v-model="is_scananywhere"
                  dense
                  :label="` ${
                    is_scananywhere.toString() === 'true' ? 'Yes' : 'No'
                  }`"
                  inset
                  class="mt-n2"
                >
                </v-switch>
              </div>
              <span class="font-weight-medium"
                >Scan anywhere is enabled at the organization level (cannot be
                disabled). By default, it is disabled for all individuals.
                However, access can be enabled/disabled at the individual
                level.</span
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
      <!-- <v-toolbar dense class="elevation-0 mt-6" flat>
        <v-spacer></v-spacer>
        <v-btn small depressed
        class="gradient-bg white--text" @click="save_scan()"><v-icon class="mr-2">mdi-content-save</v-icon> Save Changes</v-btn>
      </v-toolbar> -->
    </v-card>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import { GetCurrentUserDetailsnew } from "@/mixins/getCurrentUserDetails.js";
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { scans_settings, onboard_org_settings } from "@/graphql/mutations.js";
export default {
  components: {
    Overlay,
    SnackBar,
  },
  mixins: [GetCurrentUserDetailsnew],
  data: () => ({
    is_whatsapp: false,
    is_beacon_enabled: false,
    is_face_enabled: false,
    is_qr_enabled: false,
    is_simple_scan: true,
    is_prxyface_enabled: false,
    SnackBarComponent: {},
    is_proxy_enabled: false,
    is_member_phone: false,
    is_scananywhere: true,
    overlay: false,
    height: 0,
  }),
  async created() {
    await this.GetCurrentUserDetailsnew();
    this.is_simple_scan =
      this.$store.getters.get_org_details[0].is_simple_scan_enabled == null ||
      this.$store.getters.get_org_details[0].is_simple_scan_enabled == undefined
        ? true
        : this.$store.getters.get_org_details[0].is_simple_scan_enabled;

    this.is_qr_enabled = this.$store.getters.get_org_details[0].is_qr_enabled;
    this.is_beacon_enabled =
      this.$store.getters.get_org_details[0].is_beacon_enabled;
    this.is_face_enabled =
      this.$store.getters.get_org_details[0].is_face_enabled;
    this.is_whatsapp = this.$store.getters.get_org_details[0].is_whatsapp;
    this.is_proxy_enabled =
      this.$store.getters.get_org_details[0].is_proxy_enabled;
    this.is_prxyface_enabled =
      this.$store.getters.get_org_details[0].is_blink_eyes;
    this.is_member_phone =
      this.$store.getters.get_org_details[0].is_phone_number_mandatory;
    // console.log(this.$store.getters.get_org_details[0]);
    this.height = window.innerHeight - 50;
  },
  methods: {
    async phone_numbercall() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(onboard_org_settings, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,

              is_phone_number_mandatory: this.is_member_phone ? true : false,
            },
          })
        );

        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText:
            this.is_member_phone == true
              ? "Phone Number is Mandatory "
              : "Phone Number is not  Mandatory",
        };
        this.overlay = false;
        this.isLoading = false;

        this.GetCurrentUserDetailsnew();
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async Qr_swipe() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(scans_settings, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              scan_type: "QR",
              is_boolean: this.is_qr_enabled ? true : false,
            },
          })
        );

        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText:
            this.is_qr_enabled == true
              ? "QR  Enabeld successfully"
              : "QR  Disabeled successfully",
        };
        this.overlay = false;
        this.isLoading = false;

        this.GetCurrentUserDetailsnew();
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async simplescan_swipe() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(onboard_org_settings, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,

              is_simple_scan_enabled: this.is_simple_scan ? true : false,
            },
          })
        );

        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText:
            this.is_simple_scan == true
              ? "Simple Scan Enabeld successfully"
              : "Simple Scan Disabeled successfully",
        };
        this.overlay = false;
        this.isLoading = false;

        this.GetCurrentUserDetailsnew();
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async proxy_swipe() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(scans_settings, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              scan_type: "PROXY",
              is_boolean: this.is_proxy_enabled ? true : false,
            },
          })
        );

        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText:
            this.is_proxy_enabled == true
              ? "Proxy Swipe Enabeld successfully"
              : "Proxy Swipe Disabeled successfully",
        };
        this.overlay = false;
        this.isLoading = false;

        this.GetCurrentUserDetailsnew();
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async face_swipe() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(scans_settings, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              scan_type: "FACE",
              is_boolean: this.is_face_enabled ? true : false,
            },
          })
        );
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText:
            this.is_face_enabled == true
              ? "Face  Enabeld successfully"
              : "Face  Disabeled successfully",
        };
        this.overlay = false;
        this.isLoading = false;

        this.GetCurrentUserDetailsnew();
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async beacon_swipe() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(scans_settings, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              scan_type: "BEACON",
              is_boolean: this.is_beacon_enabled ? true : false,
            },
          })
        );
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText:
            this.is_beacon_enabled == true
              ? "Beacon Enabeld successfully"
              : "Beacon Disabeled successfully",
        };
        this.overlay = false;
        this.isLoading = false;

        this.GetCurrentUserDetailsnew();
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async whatsapp_swipe() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(scans_settings, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              scan_type: "WHATSAPP",
              is_boolean: this.is_whatsapp ? true : false,
            },
          })
        );
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Updated successfully",
        };
        this.overlay = false;
        this.isLoading = false;
        this.GetCurrentUserDetailsnew();
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async proxy_eyeswipe() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(scans_settings, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              scan_type: "PROXY_EYE_BLINK",
              is_boolean: this.is_prxyface_enabled ? true : false,
            },
          })
        );

        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText:
            this.is_prxyface_enabled == true
              ? "Proxy Eye Blink Enabeld successfully"
              : "Proxy Eye Blink Disabeled successfully",
        };
        this.overlay = false;
        this.isLoading = false;

        this.GetCurrentUserDetailsnew();
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.fixed-height-card {
  height: 150x;
  overflow-y: auto;
  word-wrap: break-word;
}
</style>